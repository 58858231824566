.invite-container {
    background-color: #f9f9f9; /* Light background for contrast */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Padding for inner spacing */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    text-align: center; /* Center-align text */
    max-width: 400px; /* Limit max width */
    margin: 20px auto; /* Center horizontally with margin */
}

.invite-title {
    font-size: 1.5rem; /* Larger font for title */
    color: #333; /* Darker text color */
    margin-bottom: 15px; /* Space below title */
}

.invite-text {
    font-size: 1rem; /* Standard font size */
    color: #555; /* Medium gray for text */
    margin-bottom: 20px; /* Space below text */
    word-wrap: break-word; /* Break long URLs */
}

.copy-button {
    background-color: #007bff; /* Bootstrap primary color */
    color: white; /* White text for contrast */
    border: none; /* No border */
    border-radius: 5px; /* Rounded button */
    padding: 10px 15px; /* Padding for button */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition */
}

.copy-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.copy-button:active {
    background-color: #004085; /* Even darker on click */
}
